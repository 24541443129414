import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

//import Navigation from "./Navigation";
//import Footer from "./Footer";

const LayoutLite = ({ children }) => {
  useEffect(() => {
    try {
      const UIkit = require("uikit/dist/js/uikit")
      const Icons = require("uikit/dist/js/uikit-icons")
      UIkit.use(Icons)
    } catch (e) {
      console.error("UIKit ERROR", e)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQueryLite {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet title={data.site.siteMetadata.title}>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta
          name="google-site-verification"
          content="IRdl643QG669GnneNd_Cvcu3MSutVoYsCtOKDWVe94Q"
        ></meta>
        
        // OneTrust Cookies Consent Notice start for vindicia.com
        <script 
          type="text/javascript" 
          src="https://cdn.cookielaw.org/consent/bff98548-2879-451a-90c1-638a780999b1/OtAutoBlock.js" >
        </script>
        <script 
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript" 
          charset="UTF-8" 
          data-domain-script="bff98548-2879-451a-90c1-638a780999b1" >
        </script>
        <script type="text/javascript">
          function OptanonWrapper() { }
        </script>
        // OneTrust Cookies Consent Notice end for vindicia.com

        <script type="text/plain" class="optanon-category-4">
          {`
                (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;})(window,document.documentElement,'async-hide','dataLayer',4000,{'GTM-WBZK3S3':true});
                function OptanonWrapper() { };
                (function() { var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true; po.src = '//api.at.getsocial.io/widget/v1/gs_async.js?id=8e2137'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s); })();

                "use strict";
                !function() {
                  var t = window.driftt = window.drift = window.driftt || [];
                  if (!t.init) {
                    if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
                    t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
                    t.factory = function(e) {
                      return function() {
                        var n = Array.prototype.slice.call(arguments);
                        return n.unshift(e), t.push(n), t;
                      };
                    }, t.methods.forEach(function(e) {
                      t[e] = t.factory(e);
                    }), t.load = function(t) {
                      var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                      o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                      var i = document.getElementsByTagName("script")[0];
                      i.parentNode.insertBefore(o, i);
                    };
                  }
                }();
                drift.SNIPPET_VERSION = '0.3.1';
                drift.load('5a28chabm77p');

                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:981655,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
        </script>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css?family=Permanent+Marker" rel="stylesheet" />
      </Helmet>

      <main>{children}</main>
    </>
  )
}

LayoutLite.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutLite
